import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54')
];

export const server_loads = [0,4];

export const dictionary = {
		"/": [~8],
		"/chiliz/chz": [11,[2]],
		"/chiliz/chz/claim": [12,[2]],
		"/chiliz/chz/my-stakings": [13,[2]],
		"/chiliz/chz/staking": [14,[2]],
		"/chiliz/chz/unstake": [15,[2]],
		"/connect/substrate/[network]/[token]": [~16],
		"/ethereum/eth": [17],
		"/ethereum/eth/my-stakings": [18],
		"/ethereum/eth/staking": [19],
		"/ethereum/eth/unstake": [20],
		"/ethereum/eth/withdraw": [21],
		"/ethereum/pol": [22,[3]],
		"/ethereum/pol/claim": [23,[3]],
		"/ethereum/pol/move-stake": [24,[3]],
		"/ethereum/pol/my-stakings": [25,[3]],
		"/ethereum/pol/restake": [26,[3]],
		"/ethereum/pol/staking": [27,[3]],
		"/ethereum/pol/unstake": [28,[3]],
		"/ethereum/pol/withdraw": [29,[3]],
		"/klever/kdas": [39,[4]],
		"/klever/[token]": [~30,[4]],
		"/klever/[token]/claim": [~31,[4]],
		"/klever/[token]/my-stakings": [~32,[4]],
		"/klever/[token]/staking": [~33,[4]],
		"/klever/[token]/undelegate": [~34,[4]],
		"/klever/[token]/unstake": [~35,[4]],
		"/klever/[token]/validators": [~36,[4]],
		"/klever/[token]/validators/delegate": [~37,[5]],
		"/klever/[token]/withdraw": [~38,[4]],
		"/substrate/[network]/[token]": [~40,[6]],
		"/substrate/[network]/[token]/claim": [~41,[6]],
		"/substrate/[network]/[token]/my-stakings": [~42,[6]],
		"/substrate/[network]/[token]/staking": [~43,[6]],
		"/substrate/[network]/[token]/unstake": [~44,[6]],
		"/substrate/[network]/[token]/withdraw": [~45,[6]],
		"/tron/trx": [46,[7]],
		"/tron/trx/claim": [47,[7]],
		"/tron/trx/my-stakings": [48,[7]],
		"/tron/trx/staking": [49,[7]],
		"/tron/trx/unstake-legacy": [51,[7]],
		"/tron/trx/unstake": [50,[7]],
		"/tron/trx/vote": [52,[7]],
		"/tron/trx/vote/[address]": [53,[7]],
		"/tron/trx/withdraw": [54,[7]],
		"/[network]/chz/result": [~10],
		"/[network]/[token]/result": [~9]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';